<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
        <b-sidebar id="add_CrmCase" aria-labelledby="sidebar-no-header-title" width="900px" no-header backdrop style="direction:ltr" right title="اضافة عميل" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>{{$parent.lang.add_customer}}</span>
                </div>
                <div @click="hide" id="hidaddcus" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{$parent.lang.close}}</span>
            </div>
        </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <div class="tobbuttom backBlack" :style="`direction:${$parent.lang.dir};padding:7px;width:100%;text-align:center;`">
                <b-button :style="`border-radius:0;background:transparent;color:#fff;border:none;`">{{$parent.lang.customer_type}}</b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="cInd" :class="`dispFlex btn btn-small redColor borderRad_`+$parent.lang.algin" @click="changeCusType(0)" >{{$parent.lang.individual_client}} <i id="indiv"  class="fas fa-arrow-down"></i></b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="cCom" :class="`dispFlex btn btn-small weightColor borderRad_none`" @click="changeCusType(1)" >{{$parent.lang.company_clinet}} <i id="comps"  class="fas fa-arrow-down hideme"></i></b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="cGov" :class="`dispFlex btn btn-small weightColor borderRad_`+$parent.lang.lalgin" @click="changeCusType(2)" >{{$parent.lang.government_clinet}} <i id="goves" class="fas fa-arrow-down hideme"></i></b-button>
            </div>
            <v-row>
                <!-- <v-col cols="12" md="2" sm="12">
                    <label>{{$parent.lang.customerid}}</label>
                    <b-form-input
                        class="inborder"
                        :label='$parent.lang.customerid'
                        v-model="customers.customerid"
                        type="number"
                        :rules="mobileRules"
                        >{{ customers.mobile }}</b-form-input>
                </v-col> -->
                <v-col cols="12" md="3" sm="12">
                    <label>{{$parent.lang.mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label='$parent.lang.mobile'
                        v-model="customers.mobile"
                        @change="checkCustomer()"
                        type="number"
                        :rules="mobileRules"
                        >{{ customers.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{$parent.lang.additional_mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.additional_mobile"
                        v-model="customers.mobile1"
                        @change="checkCustomer()"
                        type="number"
                        >{{ customers.mobile1 }}</b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                    <label>{{$parent.lang.customer_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.customer_name"
                        v-model="customers.full_name"
                        :rules="nameRules"
                        >{{ customers.full_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showCom">
                    <label>{{$parent.lang.company_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.company_name"
                        v-model="customers.company_name"
                        >{{ customers.company_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showCom">
                    <label>{{$parent.lang.company_vatid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.company_vatid"
                        v-model="customers.company_vatid"
                        >{{ customers.company_vatid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="12" v-if="!showCom">
                    <label>{{$parent.lang.city}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.city"
                        v-model="customers.city"
                        >{{ customers.city }}</b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="12" v-if="!showCom">
                    <label>{{$parent.lang.industrial}}</label>
                    <b-form-select
                        class="selborder"
                        :label="$parent.lang.industrial"
                        v-model="industrial"
                        :options="$parent.InDustr"
                        ></b-form-select>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{$parent.lang.building_no}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.building_no"
                        v-model="customers.building_no"
                        >{{ customers.building_no }}</b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="12" v-if="showCom">
                    <label>{{$parent.lang.street_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.street_name"
                        v-model="customers.street_name"
                        >{{ customers.street_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{$parent.lang.branch_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.branch_number"
                        v-model="customers.branch_number"
                        >{{ customers.branch_number }}</b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="12" v-if="showCom">
                    <label>{{$parent.lang.district}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.district"
                        v-model="customers.district"
                        >{{ customers.district }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{$parent.lang.zipcode}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.zipcode"
                        v-model="customers.zipcode"
                        >{{ customers.zipcode }}</b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="12" v-if="showCom">
                    <label>{{$parent.lang.city}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.city"
                        v-model="customers.city"
                        >{{ customers.city }}</b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showCom">
                    <label>{{$parent.lang.address}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.address"
                        v-model="customers.address"
                        >{{ customers.address }}</b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showGov">
                    <label>{{$parent.lang.entity_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.entity_name"
                        v-model="customers.entity_name"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showGov">
                    <label>{{$parent.lang.branch_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.branch_name"
                        v-model="customers.branch_name"
                        ></b-form-input>
                </v-col>
            </v-row>
            <v-row v-if="$store.state.licenseType.crm">
                <v-col cols="12" md="3" sm="6">
                    <label>{{$parent.lang.work_type}}</label>
                    <b-form-select
                        class="selborder"
                        :label="$parent.lang.work_type"
                        v-model="work_type"
                        :options="opts.crmType"
                        ></b-form-select>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                    <label>{{$parent.lang.next_action}}</label>
                    <b-form-select
                        class="selborder"
                        :label="$parent.lang.next_action"
                        v-model="next_action"
                        :options="opts.crmActions"
                        ></b-form-select>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                    <label>{{$parent.lang.status}}</label>
                    <b-form-select
                        class="selborder"
                        :label="$parent.lang.status"
                        v-model="status"
                        :options="opts.crmStatus"
                        ></b-form-select>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                    <label>{{$parent.lang.amount}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.amount"
                        v-model="amount"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                    <label>{{$parent.lang.copies}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.copies"
                        v-model="copies"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                    <label>{{$parent.lang.include_computers}}</label>
                    <b-form-select
                        class="selborder"
                        :label="$parent.lang.include_computers"
                        v-model="computer"
                        :options="compOptions"
                        ></b-form-select>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                    <label>{{$parent.lang.agent}}</label>
                    <b-form-select
                        class="selborder"
                        :label="$parent.lang.agent"
                        v-model="agent"
                        :options="opts.agents" 
                        ></b-form-select>
                </v-col>
                
                <v-col cols="12" md="6" sm="12">
                    <label>{{$parent.lang.next_action_date}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.next_action_date"
                        v-model="next_action_date"
                        type="date"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{$parent.lang.notes}}</label>
                    <b-form-textarea
                        class="inborder"
                        :label="$parent.lang.notes"
                        v-model="notes"
                        ></b-form-textarea>
                </v-col>
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
            <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="addCustomer();" variant="success" class="ma-2" style="width:100px;">{{$parent.lang.add}}</b-button>
            </div>
      </template>
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
export default{
    data: () => ({
        work_type: 1,
        next_action: 1,
        status: 1,
        agent: 1,
        notes: "",
        copies: 1,
        amount: 1350,
        computer: 0,
        modal: false,
        industrial: 1,
        // city: "الرياض",
        next_action_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        customers:{
            id: 0,
            mobile: '',
            mobile1: '',
            full_name: '',
            company_name: '',
            company_vatid: '',
            building_no: '',
            street_name: '',
            branch_number: '',
            district: '',
            zipcode: '',
            city:'',
            address:'',
            customer_type: 1,
            entity_name: '',
            branch_name: '',
            
        },
        showCom: false,
        showGov: false,
    }),
    computed: {
        mobileRules: function() {
            return [
                v => !!v || this.$parent.lang.required_field,
                v => (v && v.length <= 10) || this.$parent.lang.mobil_10_digits,
            ]
        },
        nameRules: function() {
            return [
                v => !!v || this.$parent.lang.required_field,
            ]
        },
        compOptions: function() {
            return [
                {value: 0, text: this.$parent.lang.no_devices},
                {value: 1, text: this.$parent.lang.yes_19},
                {value: 2, text: this.$parent.lang.yes_24},
            ];
        },
        opts: function(){
            let t = {
                crmActions: [],
                crmStatus: [],
                crmType: [],
                agents: []
            };
            Object.keys(this.$parent.Statics).forEach(key => {
                Object.keys(this.$parent.Statics[key]).forEach(keys => {
                    // // console.log("todo" , this.$parent.Statics[key][keys])
                    t[key].push({
                        value: this.$parent.Statics[key][keys].id,
                        text: this.$parent.Statics[key][keys][`name_`+this.$parent.lang.langname]
                    })
                })
            })
            for(let i = 0;i < this.$parent.users.length;i++){
                t.agents.push({
                    value: this.$parent.users[i].id,
                    text: this.$parent.users[i].full_name
                })
            }
            return t;
        }
    }, 
    methods: {
        checkCustomer(){
            if(this.customers.mobile == '' && this.customers.mobile1 == ''){return false;}
            else{
                let post = new FormData();
                post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
                let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
                const path = this.$router.currentRoute.path;
                post.append("type", "getCustomerData");
                post.append("auth", cook);
                post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
                post.append("data[mobile]", this.customers.mobile);
                post.append("data[mobile1]", this.customers.mobile1);
                axios.post(this.$SAMCOTEC.r_path, post).then((response) => {
                    if (response && response.data && response.data.results) {
                        this.customers = response.data.results.data;
                        this.changeCusType(parseFloat(response.data.results.data.customer_type) - 1)
                    }
                });
            }
        },
        changeCusType(id){
            if(id == 0){
                this.customers.customer_type = 1;
                // this.showcamp = false;
                this.showCom = false;
                this.showGov = false;
                document.getElementById('indiv').classList.remove('hideme');
                document.getElementById('comps').classList.add('hideme');
                document.getElementById('goves').classList.add('hideme');

                document.getElementById('cInd').classList.remove('redColor');
                document.getElementById('cCom').classList.remove('redColor');
                document.getElementById('cGov').classList.remove('redColor');

                document.getElementById('cInd').classList.add('redColor');
                document.getElementById('cCom').classList.add('weightColor');
                document.getElementById('cGov').classList.add('weightColor');

            }else if(id == 1){
                this.customers.customer_type = 2;
                // this.showcamp = true;
                this.showCom = true;
                this.showGov = false;
                document.getElementById('comps').classList.remove('hideme');
                document.getElementById('indiv').classList.add('hideme');
                document.getElementById('goves').classList.add('hideme');

                document.getElementById('cInd').classList.remove('redColor');
                document.getElementById('cCom').classList.remove('redColor');
                document.getElementById('cGov').classList.remove('redColor');

                document.getElementById('cInd').classList.add('weightColor');
                document.getElementById('cCom').classList.add('redColor');
                document.getElementById('cGov').classList.add('weightColor');

            }
            else if(id == 2){
                this.customers.customer_type = 3;
                // this.showcamp = true;
                this.showCom = false;
                this.showGov = true;
                document.getElementById('goves').classList.remove('hideme');
                document.getElementById('comps').classList.add('hideme');
                document.getElementById('indiv').classList.add('hideme');

                document.getElementById('cInd').classList.remove('redColor');
                document.getElementById('cCom').classList.remove('redColor');
                document.getElementById('cGov').classList.remove('redColor');

                document.getElementById('cInd').classList.add('weightColor');
                document.getElementById('cCom').classList.add('weightColor');
                document.getElementById('cGov').classList.add('redColor');

            }
            // this.changeCtype();
        },
        addCustomer(){
            // // console.log(this.customers.mobile);
            let vald = true;
            let message = "";
            if(this.customers.mobile == '' || this.customers.mobile.length < 5 || this.customers.mobile.substring(0,2) != "05"){
                vald = false;
                message = this.$parent.lang.mobile_info_required;
            }
            else if((this.customers.full_name == '' || this.customers.full_name.length <= 5)  && vald == true){
                vald = false;
                message = this.$parent.lang.name_info_required;
            }
            else if((this.customers.customer_type == 2 || this.customers.customer_type == 3) && vald == true){
                if(this.customers.company_name == '' || this.customers.address == ''){
                    vald = false;
                    message = this.$parent.lang.customer_company_info_required;
                }
            }

            if(vald == false){
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append("type" , "addCustomerCrm");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[id]",this.customers.id)
            post.append("data[mobile]",this.customers.mobile)
            post.append("data[mobile1]",this.customers.mobile1)
            post.append("data[full_name]",this.customers.full_name)
            post.append("data[company_name]",this.customers.company_name);
            post.append("data[company_vatid]",this.customers.company_vatid);
            post.append("data[building_no]",this.customers.building_no);
            post.append("data[street_name]",this.customers.street_name);
            post.append("data[branch_number]",this.customers.branch_number);
            post.append("data[district]",this.customers.district);
            post.append("data[zipcode]",this.customers.zipcode);
            post.append("data[city]",this.customers.city);
            post.append("data[address]",this.customers.address);
            post.append("data[customer_type]",this.customers.customer_type);
            post.append("data[entity_name]",this.customers.entity_name);
            post.append("data[branch_name]",this.customers.branch_name);

            post.append("data[work_type]",this.work_type);
            post.append("data[next_action]",this.next_action);
            post.append("data[status]",this.status);
            post.append("data[agent]",this.agent);
            post.append("data[notes]",this.notes);
            post.append("data[next_action_date]",this.next_action_date);
            post.append("data[copies]",this.copies);
            post.append("data[amount]",this.amount);
            post.append("data[computer]",this.computer);
            post.append("data[industrial]",this.industrial);


            axios.post(
                this.$SAMCOTEC.r_path , post
            ).then((response) => {
                var res = response.data;
                if(res.error.number == 200){
                    if (this.$snotify.notifications.length > 0) {
                        this.$snotify.notifications.forEach(notification => {
                            this.$snotify.remove(notification.id)
                        });
                    }
                    message = this.$parent.lang.customer_addedd;
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    this.work_type =  1;
                    this.next_action =  1;
                    this.status =  1;
                    this.agent =  1;
                    this.notes = "";
                    this.copies =  1;
                    this.amount = 1350;
                    this.computer =  0;
                    this.customers = {
                        mobile: '',
                        mobile1: '',
                        full_name: '',
                        company_name: '',
                        company_vatid: '',
                        building_no: '',
                        street_name: '',
                        branch_number: '',
                        district: '',
                        zipcode: '',
                        city:'',
                        address:'',
                        customer_type: 1,
                        entity_name: '',
                        branch_name: '',
                    }
                    this.$parent.getClients();
                    document.getElementById('hidaddcus').click();
                }else{
                    message = res.error.message;
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    
                    if(res.error.number == 202){
                        this.$parent.customer.mobile = this.customers.mobile;
                        this.$parent.customer.full_name = this.customers.full_name;
                        this.customers = {
                            mobile: '',
                            mobile1: '',
                            full_name: '',
                            email: ''
                        }
                        document.getElementById("add_customer").style.display = "none"
                    }
                }
            })
        }
    },
}
</script>